 <template>
	<el-dialog title="添加客户" top="1vh" width="600px" :visible.sync="is_show_in_page">
		<el-form label-width='90px'>
			<el-form-item label="客户手机">
				<el-input v-model="form.tel" clearable></el-input>
			</el-form-item>
			<el-form-item label="客户名称">
				<el-input v-model="form.user_name" clearable></el-input>
			</el-form-item>
			<el-form-item label="客户公司">
				<el-input v-model="form.company_name" clearable></el-input>
			</el-form-item>
			<el-form-item label="预处理模板">
				<el-select v-model="form.pretreatment_method" style="width:100%" clearable>
					<el-option v-for="(item,index) of pretreatment_list" :key="index" :label="item.cname" :value="item.pretreatment_method"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="备注">
				<el-input v-model="form.mark" clearable></el-input>
			</el-form-item>
			<el-form-item >
				<el-button type="primary" @click="sub">提交</el-button>
			</el-form-item>
		</el-form>
	</el-dialog>
</template>

<script>
	export default {
		props:{
			is_show:Number,
			pretreatment_list:Array,
		},
		data() {
			return {
				
				//是否显示
				is_show_in_page:false,

				//表单
				form:{
					tel:'',
					user_name:'',
					company_name:'',
					pretreatment_method:'',
					mark:'',
				},
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){

					//打开弹出层
					this.is_show_in_page=true;
					
					//初始化
					this.init();

				}else{

					//关闭弹出层
					this.is_show_in_page=false;
				}
			}
		},
		methods: {

			//初始化
			init(){
				this.form.tel=''
				this.form.user_name=''
				this.form.company_name=''
				this.form.pretreatment_method=''
				this.form.mark=''
			},

			//提交
			sub(){

				if(!this.$my.check.is_tel(this.form.tel)){
					this.$my.other.msg({
						type:'warning',
						str:"客户电话有误,请检查"
					});
					return;
				}

				if(!this.form.user_name.trim()){
					this.$my.other.msg({
						type:'warning',
						str:"客户名称为空,请输入"
					});
					return;
				}

				if(!this.form.company_name.trim()){
					this.$my.other.msg({
						type:'warning',
						str:"公司名称为空,请输入"
					});
					return;
				}

				if(!this.form.pretreatment_method){
					this.$my.other.msg({
						type:"warning",
						str:'缺少预处理模板'
					});
					return;
				}

				//调用接口
				this.$my.net.req({
					data:{
						mod:'customer',
						ctr:'user_add_by_admin',
						...this.form
					},
					callback:(data)=>{

						//报告结果
						this.$my.other.msg({
							type:'success',
							str:"添加成功"
						});

						//关闭弹出层
						this.is_show_in_page=false;

						//通知
						this.$emit("added");
					}
				});
			}
		}
	}
</script>